<template>
  <div>
    <Header/>
    <b-container
      style="max-width:90%; padding-top:70px; "
    >
      <b-row>
        <b-col>
          <b-card >
            <b-card-header
              class="row"
            >
              <b-col>
                オリジナル写真ダウンロード
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  @click="getNextCalendar('original')"
                  :disabled="startOriginalCalendarFlag"
                >
                  次へ
                </b-button>
                <b-button
                  variant="primary"
                  class="ms-1"
                  @click="getNewCalendar('original')"
                  :disabled="startOriginalCalendarFlag"
                >
                  最新
                </b-button>
                <b-button
                  variant="primary"
                  class="ms-1"
                  @click="getPrevCalendar('original')"
                >
                  前へ
                </b-button>
              </b-col>
            </b-card-header>
            <b-list-group
              v-for="(originalCalendarList,calendarIndex) of originalCalendar"
              :key="calendarIndex"
            >
              <b-list-group-item
                      :disabled="originalCalendarList.isDownload"
                      button
                      v-b-toggle="`collapse-${ calendarIndex }`"
              >
                {{ originalCalendarList.date }}
              </b-list-group-item>
              <b-collapse :id="`collapse-${ calendarIndex }`">
                <b-card>
                  <b-card-body>
                    <b-card-text>
                      <b-button class="ms-4" style="margin-top: 5px"
                                v-for="hIndex in hoursList"
                                :key="hIndex.value"
                                @click="getKeyPath(originalCalendarList.date,hIndex.value,'originalImg')"
                      >
                        {{ hIndex.label }}
                      </b-button>
                      <b-button class="col-9 ms-4" style="margin-top: 5px;margin-left: 25px;"
                                @click="getKeyPath(originalCalendarList.date,24,'originalImg')"
                      >
                        全件
                      </b-button>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-collapse>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col>
          <b-card
          >
            <b-card-header
              class="row"
            >
              <b-col>
                AI修正写真ダウンロード
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  @click="getNextCalendar('AI')"
                  :disabled="startAiCalendarFlag"
                >
                  次へ
                </b-button>
                <b-button
                  variant="primary"
                  class="ms-1"
                  @click="getNewCalendar('AI')"
                  :disabled="startAiCalendarFlag"
                >
                  最新
                </b-button>
                <b-button
                  variant="primary"
                  class="ms-1"
                  @click="getPrevCalendar('AI')"
                >
                  前へ
                </b-button>
              </b-col>
            </b-card-header>
            <b-list-group
              v-for="(aiCalendarList,calendarIndex) of AiCalendar"
              :key="calendarIndex"
            >
              <b-list-group-item
                      :disabled="aiCalendarList.isDownload"
                      button
                      v-b-toggle="`collapse-ai-${ calendarIndex }`"
              >
                {{ aiCalendarList.date }}
              </b-list-group-item>
              <b-collapse :id="`collapse-ai-${ calendarIndex }`">
                <b-card>
                  <b-card-body>
                    <b-card-text>
                      <b-button class="ms-4" style="margin-top: 5px"
                                v-for="hIndex in hoursList"
                                :key="hIndex.value"
                                @click="getKeyPath(aiCalendarList.date,hIndex.value,'AIRevisedImg')"
                      >
                        {{ hIndex.label }}
                      </b-button>
                      <b-button class="col-9 ms-4" style="margin-top: 5px;margin-left: 25px;"
                                @click="getKeyPath(aiCalendarList.date,24,'AIRevisedImg')"
                      >
                        全件
                      </b-button>
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-collapse>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import aws from 'aws-sdk'
import moment from 'moment'
import fileSaver from 'file-saver'

export default {
  title:'物件写真検品',
  components:{
    Header
  },
  data() {
    return {
      s3:'',
      selectList:[],
      hoursList:[
        {
        value:0,
        label:'00時～01時間'
        },
        {
        value:1,
        label:'01時～02時間'
        },
        {
          value:2,
          label:'02時～03時間'
        },
        {
          value:3,
          label:'03時～04時間'
        },
        {
          value:4,
          label:'04時～05時間'
        },
        {
          value:5,
          label:'05時～06時間'
        },
        {
          value:6,
          label:'06時～07時間'
        },
        {
          value:7,
          label:'07時～08時間'
        },
        {
          value:8,
          label:'08時～09時間'
        },
        {
          value:9,
          label:'09時～10時間'
        },
        {
          value:10,
          label:'10時～11時間'
        },
        {
          value:11,
          label:'11時～12時間'
        },
        {
          value:12,
          label:'12時～13時間'
        },
        {
          value:13,
          label:'13時～14時間'
        },
        {
          value:14,
          label:'14時～15時間'
        },
        {
          value:15,
          label:'15時～16時間'
        },
        {
          value:16,
          label:'16時～17時間'
        },
        {
          value:17,
          label:'17時～18時間'
        },
        {
          value:18,
          label:'18時～19時間'
        },
        {
          value:19,
          label:'19時～20時間'
        },
        {
          value:20,
          label:'20時～21時間'
        },
        {
          value:21,
          label:'21時～22時間'
        },
        {
          value:22,
          label:'22時～23時間'
        },
        {
          value:23,
          label:'23時～24時間'
        },
      ],
      toDay:'',
      originalCalendar:[],
      AiCalendar:[],
      limit:10,
      imgData:'',
      originalData:[],
      s3Folder:[
        { folderName:'mansion' },
        { folderName:'property' },
        { folderName:'facility' }
      ],
      contentLists:[],
      userPath:[],
      addressPath:[],
      messageType:'',
      selectDay:'',
      startOriginalCalendarFlag:true,
      startAiCalendarFlag:true,
      s3Bucket:'',
      downloadPath:[],
      totalCount:'',
      continuationToken:'',
      downloadCount:0
    }
  },
  created() {
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  mounted() {
    this.toDay = moment().format('YYYY-MM-DD')
    this.createCalendar(this.toDay)
  },
  methods:{
    //カレンダー作成
    createCalendar(day) {
      this.originalCalendar.push({
        date:day,
        isDownload:false
      })
      this.AiCalendar.push({
        date:day,
        isDownload:false
      })
      for(let i = 0;i < this.limit;i++) {
        this.originalCalendar.push({
          date:moment(day).add(- (i + 1),"day").format('YYYY-MM-DD'),
          isDownload:false
        })
        this.AiCalendar.push({
          date:moment(day).add(- (i + 1),"day").format('YYYY-MM-DD'),
          isDownload:false
        })
      }
    },
    //最新を押されたとき
    getNewCalendar(type) {
      if(type == 'original') {
        this.originalCalendar = []
        this.startOriginalCalendarFlag = true
        this.originalCalendar.push({
            date:this.toDay,
            isDownload:false
          })
        for(let i = 0;i < this.limit;i++) {
          this.originalCalendar.push({
            date:moment(this.toDay).add(- (i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })

        }
      } else if(type == 'AI') {
        this.AiCalendar = []
        this.startAiCalendarFlag = true
        this.AiCalendar.push({
          date:this.toDay,
          isDownload:false
        })
        for(let i = 0;i < this.limit;i++) {
          this.AiCalendar.push({
            date:moment(this.toDay).add(- (i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })

        }
      }
    },
     //次へを押された時
    getNextCalendar(type) {
      let day = ''
      if(type == 'original') {
        day = this.originalCalendar[0].date
        this.originalCalendar = []
        for(let i = 0;i < 11;i++) {
          this.originalCalendar.push({
            date:moment(day).add((i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })
        }
        this.originalCalendar.sort((a, b) => a.date < b.date ? 1 : -1);
        if(this.originalCalendar[0].date == this.toDay) {
          this.startOriginalCalendarFlag = true
        }
      } else if(type == 'AI') {
        day = this.AiCalendar[0].date
        this.AiCalendar = []
        for(let i = 0;i < 11;i++) {
          this.AiCalendar.push({
            date:moment(day).add((i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })
        }
        this.AiCalendar.sort((a, b) => a.date < b.date ? 1 : -1);
        if(this.AiCalendar[0].date == this.toDay) {
          this.startAiCalendarFlag = true
        }
      }

    },
    //前へを押されたとき
    getPrevCalendar(type) {
      let count = ''
      let day = ''

      if(type == 'original') {
        this.startOriginalCalendarFlag = false
        count = this.originalCalendar.length - 1
        day = this.originalCalendar[count].date
        this.originalCalendar = []
        for(let i = 0;i < 11;i++) {
          this.originalCalendar.push({
            date:moment(day).add(- (i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })
        }
      } else if(type == 'AI') {
        this.startAiCalendarFlag = false
        count = this.AiCalendar.length - 1
        day = this.AiCalendar[count].date
        this.AiCalendar = []
        for(let i = 0;i < 11;i++) {
          this.AiCalendar.push({
            date:moment(day).add(- (i + 1),"day").format('YYYY-MM-DD'),
            isDownload:false
          })
        }
      }
    },
    //ダウンロード日付をチェックしdisabledに
    checkDownloadDay(day,type) {
      if(type == 'originalImg') {
        for(let calendar of this.originalCalendar) {
          if(calendar.date == day) {
            calendar.isDownload = true
          }
        }
      } else {
        for(let calendar of this.AiCalendar) {
          if(calendar.date == day) {
            calendar.isDownload = true
          }
        }
      }

    },
    //S3のキーパスを取得
    async getKeyPath(day,hours,type) {
      let messageType = ''
      this.downloadPath = []
      this.downloadCount = 0

      if(type == 'AIRevisedImg') {
        messageType = 'AI修正済み写真'
      }else {
        messageType = 'オリジナル写真'
      }
      this.$swal({
        title: 'ダウンロード中',
        html: `「${day}の${messageType}」をダウンロード中です。`,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      });
      this.checkDownloadDay(day,type)
      try {
          for(let folder of this.s3Folder) {
              let result = true
              while (result){
                  let params = {
                      Bucket: this.s3Bucket,
                      Prefix: type + '/' + folder.folderName + '/'+ day + '/' ,
                  }
                  if(this.continuationToken !== ''){
                      params.ContinuationToken = this.continuationToken
                  }
                  await this.s3.listObjectsV2(params).promise()
                      .then(response => {
                          for(let content of response.Contents) {
                              let startDt = new Date(day + " " + hours + ":00")
                              let endDt = new Date(day + " " + (hours + 1) + ":00")
                              if((startDt <= content.LastModified && content.LastModified <= endDt) || hours == 24){
                                if(
                                        content.Key.includes('.jpg') ||
                                        content.Key.includes('.jpeg') ||
                                        content.Key.includes('.JPG') ||
                                        content.Key.includes('.JPEG')
                                ) {
                                  this.downloadPath.push(content.Key)
                                } else {
                                  console.log(content)
                                }
                              }
                          }
                          if(response.IsTruncated) {
                              this.continuationToken = response.NextContinuationToken;
                          }else{
                              result = false
                              this.continuationToken = ""
                          }
                      }).catch(error => {
                          throw error;
                      });
              }
        }
        if(this.downloadPath.length != 0) {
            this.getS3Object(day,messageType)
        } else {
            this.$swal({
                icon: 'error',
                html:`「${day}の${messageType}」はありませんでした。`,
                confirmButtonText:"閉じる"
            })
            return
        }
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`ステータスエラー`,
          confirmButtonText:"閉じる"
        })
        return
      } finally {
        for(let calendar of this.originalCalendar) {
          if(calendar.date == day) {
            calendar.isDownload = false
          }
        }
        for(let calendar of this.AiCalendar) {
          if(calendar.date == day) {
            calendar.isDownload = false
          }
        }
      }
    },
     //オブジェクトダウンロード
    async getS3Object(day,messageType){
      this.$swal({
        title: 'ダウンロード中',
        html: `「${day}の${messageType}」をダウンロード中です。<br>
        ${this.downloadCount}/${this.downloadPath.length}`,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      });
      try {
        let skipCount = 0
        for(let path of this.downloadPath) {
          if(path.includes('.jpg') || path.includes('.jpeg')||
            path.includes('.JPG') || path.includes('.JPEG')) {
            await this.s3.getObject({
              Bucket: this.s3Bucket,
              Key: path
            }).promise().then(response => {
              // ダウンロードしたファイルのデータをdata.Bodyから取得
              const fileData = new Blob([response.Body], {type: response.ContentType});
              const file = new File([fileData], 'filename.jpg', { type: response.ContentType });
              // exif-jsを使用してEXIF情報を解析する例
              const EXIF = require('exif-js');
              EXIF.getData(file, function() {
                let dateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal').toString();
                dateTimeOriginal = dateTimeOriginal.replace(/(\d+):(\d+):(\d+) (\d+):(\d+):(\d+)/g, "$1-$2-$3 $4:$5:$6");
                dateTimeOriginal = moment(dateTimeOriginal).format('YYYY-MM-DD HH:mm:ss')
                if(dateTimeOriginal == '2023-01-01 17:57:59'){
                  skipCount ++
                }else{
                  fileSaver.saveAs(fileData,path.slice(1 + path.lastIndexOf('/')));
                }
              });
              this.downloadCount ++
              this.$swal({
                title: 'ダウンロード中',
                html: `「${day}の${messageType}」をダウンロード中です。<br>
                ${this.downloadCount}/${this.downloadPath.length}<br>
                内にダミー画像を${skipCount}件スキップしました`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
              });
              if(this.downloadPath.length == this.downloadCount) {
                this.$swal({
                  title: 'ダウンロード完了',
                  html: `「${day}の${messageType}」のダウンロードが完了しました。`,
                  toast: true,
                  icon:'success',
                  position: 'top-end',
                  showConfirmButton: false,
                });
                this.downloadPath = []
              }
            })
            .catch(error => {
              throw error
            });
          }
        }
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`写真データダウンロード時エラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
    },

  }
}
</script>
